.qozi {
    color: white;
    position: absolute;
    font-size: 2.5em;
    height: 1em;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 2;
    margin: auto;
    text-align: center;
    opacity: 0;
    animation: appear 4s  forwards;
    animation-iteration-count: infinite;
  }
  
  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  
      .boxcon {
          height: 200px;
          width: 200px;
      }
  
      .box1 {
        position: absolute;
          height: 200px;
          width: 200px;
          border-radius: 50%;
          border: 2px solid rgb(14, 17, 17);
          animation-name: spinx;
          animation-duration: 3s;
          animation-iteration-count: infinite;
          text-align: center;
      }
  
      .box2 {position: absolute;
          height: 200px;
          width: 200px;
          border-radius: 50%;
          border: 2px solid rgb(14, 17, 17);
          animation-name: spiny;
          animation-duration: 3s;
          animation-iteration-count: infinite;
          text-align: center;
      }
  
      @keyframes spinx {
          0% {
              transform: rotateX(0deg);
          }
  
          100% {
              transform: rotateX(360deg);
          }
      }
  
      @keyframes spiny {
          0% {
              transform: rotateY(0deg);
          }
  
          100% {
              transform: rotateY(360deg);
          }
      }