::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
   background: rgb(5,65,116);
    background: linear-gradient(90deg, rgba(5,65,116,1) 0%, rgba(0,43,73,1) 100%);
}
.formInput{font-size:14px; border-radius: 3px; padding:5px 10px;}
.formInput::placeholder {
	color: #bfbfbf;
	opacity: 0.9;
  }
.btnFormIn{position: fixed; bottom:20vh; right:0px;}
.formnew{padding:20px; background-color: white;box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;}
.tablenew{margin-top:30px;padding:20px; background-color: white;box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;}
.tablenew td{font-size:16px}
.you_col{padding:10px; background-color: white;box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;}
.mur_you{padding:10px; background-color: white;box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; display:flex; align-items: center; justify-content:space-around}
.you_btn{display:flex; width:100%; justify-content: space-between;}
.mur_you h4{ position: relative; top:5px;}
